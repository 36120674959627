// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-koledy-js": () => import("./../../../src/pages/koledy.js" /* webpackChunkName: "component---src-pages-koledy-js" */),
  "component---src-pages-portfolio-bethink-js": () => import("./../../../src/pages/portfolio/bethink.js" /* webpackChunkName: "component---src-pages-portfolio-bethink-js" */),
  "component---src-pages-portfolio-find-it-js": () => import("./../../../src/pages/portfolio/find-it.js" /* webpackChunkName: "component---src-pages-portfolio-find-it-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-portfolio-lednica-js": () => import("./../../../src/pages/portfolio/lednica.js" /* webpackChunkName: "component---src-pages-portfolio-lednica-js" */),
  "component---src-pages-portfolio-let-me-in-ux-js": () => import("./../../../src/pages/portfolio/let-me-in-ux.js" /* webpackChunkName: "component---src-pages-portfolio-let-me-in-ux-js" */),
  "component---src-pages-portfolio-portfolio-section-1-js": () => import("./../../../src/pages/portfolio/portfolioSection1.js" /* webpackChunkName: "component---src-pages-portfolio-portfolio-section-1-js" */),
  "component---src-pages-portfolio-roboticket-js": () => import("./../../../src/pages/portfolio/roboticket.js" /* webpackChunkName: "component---src-pages-portfolio-roboticket-js" */),
  "component---src-pages-portfolio-szukaj-slowa-js": () => import("./../../../src/pages/portfolio/szukaj-slowa.js" /* webpackChunkName: "component---src-pages-portfolio-szukaj-slowa-js" */),
  "component---src-pages-portfolio-teamaton-js": () => import("./../../../src/pages/portfolio/teamaton.js" /* webpackChunkName: "component---src-pages-portfolio-teamaton-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/pageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */)
}

